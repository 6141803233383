import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss']
})
export class CourseComponent implements OnInit {
  courseList = [
    {
      name: 'Class 11th',
      desc: '1 year course'
    },
    {
      name: 'Class 12th',
      desc: '1 year course'
    },
    {
      name: 'Neet fresher crash course',
      desc: '1 year course'
    },
    {
      name: 'Neet fresher 1 year course',
      desc: '1 year course'
    },
    {
      name: 'Neet repeaters',
      desc: '1 year course'
    },
    {
      name: 'Class 9th',
      desc: '1 year course'
    },
    {
      name: 'Class 10th',
      desc: '1 year course'
    }
  ]
  constructor() { }

  ngOnInit() {
  }

  openCourse(course) {

  }

}
