import { Component, OnInit } from '@angular/core';
import { FileUpload } from '../../classes/file-upload'
import * as firebase from 'firebase';
// import { FirebaseStorage } from '@angular/fire';
import { FirebaseApp } from '@angular/fire';
import { ToastrService } from 'ngx-toastr';
import { WebsiteServiceService } from 'app/services/website-service.service';

@Component({
  selector: 'app-add-notifications',
  templateUrl: './add-notifications.component.html',
  styleUrls: ['./add-notifications.component.scss']
})
export class AddNotificationsComponent implements OnInit {
  newNotification = {
    title: '',
    desc: '',
    fileUrl: '',
    type: ''
  }

  // file uploading
  imageUrl = '';
  uploading = false;
  uploadPercent = 0;
  uploaded = false;

  private pdfPath = '/notificationPDFs';
  selectedFile = null;
  currentUpload: any = null;

  constructor(
    private firebaseApp: FirebaseApp,
    private toastr: ToastrService,
    private webFireService: WebsiteServiceService
  ) { }

  ngOnInit() {
  }

  publish() {
    // console.log(this.newNotification);
    if (this.validateNotification()) {
      this.webFireService.addNotificationToFirebase(this.newNotification).then(() => {
        this.resetPageData();
      })
    }
  }
  resetPageData() {
    this.newNotification = {
      title: '',
      desc: '',
      fileUrl: '',
      type: ''
    }
    this.removeUploadedPDF();
  }
  validateNotification() {
    if (this.newNotification.type === '') {
      this.toastr.warning('Select Notification Type...')
      return false
    }
    if (this.newNotification.title.trim() === '') {
      this.toastr.warning('Enter notification title...')
      return false
    }
    if (this.newNotification.desc.trim() === '') {
      this.toastr.warning('Enter notification description...')
      return false
    }
    if (this.newNotification.fileUrl.trim() === '') {
      this.toastr.info('You Skipped PDF file upload...')
    }
    return true;
  }


  detectFiles(event) {
    // console.log(event.target.files[0]);
    this.selectedFile = event.target.files[0];
    if (this.selectedFile) {
      const file = this.selectedFile;
      this.currentUpload = new FileUpload(file);
      this.pushUploadPDF(this.currentUpload);
      this.selectedFile = null;
    } else {
      // this.toastr.error("select an image file...")
      console.log('select PDF');

    }
  }


  pushUploadPDF(upload) {
    const storageRef = this.firebaseApp.storage().ref();
    const uploadTask = storageRef.child(`${this.pdfPath}/${this.getRandomNumber() + upload.file.name}`).put(upload.file);

    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        this.uploading = true;
        // in progress
        const snap = snapshot as firebase.storage.UploadTaskSnapshot;
        this.uploadPercent = Math.round((snap.bytesTransferred / snap.totalBytes) * 100)
        // console.log(this.uploadPercent);

      },
      (error) => {
        this.uploading = false;
        // upload failed
        console.log(error);
      },
      () => {

        uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          this.uploaded = true;
          this.uploading = false;
          this.uploadPercent = 0;
          this.newNotification.fileUrl = downloadURL;
          // console.log(downloadURL);
        });
      }
    );
  }

  getRandomNumber() {
    let num = '';
    for (let i = 0; i < 15; i++) {
      num = num + '' + Math.floor(Math.random() * 10);
    }
    return num;
  }
  removeUploadedPDF() {
    this.imageUrl = '';
    this.uploading = false;
    this.uploadPercent = 0;
    this.uploaded = false;
    this.selectedFile = null;
    this.currentUpload = null;
    const pdfInput = <HTMLInputElement>document.getElementById('pdfUploadInput');
    pdfInput.value = '';
    this.newNotification.fileUrl = '';
  }
}
