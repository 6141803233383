import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WebsiteServiceService } from 'app/services/website-service.service';

@Component({
  selector: 'app-all-notifications',
  templateUrl: './all-notifications.component.html',
  styleUrls: ['./all-notifications.component.scss']
})
export class AllNotificationsComponent implements OnInit {
  allNotificationList = []
  notiType = 'all';
  loading = false;
  constructor(
    private route: ActivatedRoute,
    private webFireService: WebsiteServiceService
  ) {
    this.route.paramMap.subscribe(params => {
      this.loadNotifications(params.get('type'));
    });
  }
  loadNotifications(type) {
    this.loading = true;
    this.notiType = type
    const List = this.webFireService.getAllNotificationsonType(type);
    List.snapshotChanges().subscribe(items => {
      this.allNotificationList = [];
      items.forEach(item => {
        const jsonItem = item.payload.toJSON();
        this.allNotificationList.push(jsonItem);
      });
      this.allNotificationList.sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime());
      // console.log(this.allNotificationList);
    this.loading = false;
    });
  }
  ngOnInit() {
  }

}
