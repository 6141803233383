import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dir-desk',
  templateUrl: './dir-desk.component.html',
  styleUrls: ['./dir-desk.component.scss']
})
export class DirDeskComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
