import { Component, OnInit } from '@angular/core';
import { WebsiteServiceService } from 'app/services/website-service.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-result-page',
  templateUrl: './result-page.component.html',
  styleUrls: ['./result-page.component.scss']
})
export class ResultPageComponent implements OnInit {
  allResultList = [];
  deletingNotificationId: any = null;
  loading = false;
  isEmptyList = false;
  constructor(
    private webFireService: WebsiteServiceService,
    private toastr: ToastrService
  ) {
    this.loadResults();
  }
  ngOnInit() {
  }

  loadResults() {
    this.loading = true;
    const List = this.webFireService.getAllResults();
    List.snapshotChanges().subscribe(items => {
      this.allResultList = [];
      items.forEach(item => {
        const jsonItem = item.payload.toJSON();
        this.allResultList.push(jsonItem);
      });
      this.allResultList.sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime());
      console.log(this.allResultList);
      this.isEmptyList = this.allResultList.length == 0 ? true : false;
      this.loading = false;
    });
  }
}
