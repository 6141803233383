import { Component, OnInit } from '@angular/core';
import { WebsiteServiceService } from 'app/services/website-service.service';

@Component({
  selector: 'app-hope-notifications',
  templateUrl: './hope-notifications.component.html',
  styleUrls: ['./hope-notifications.component.scss']
})
export class HopeNotificationsComponent implements OnInit {
  generalNotiList = []
  examNotiList = []
  timetableNotiList = []

  loadingGen = false;
  loadingExam = false;
  loadingTT = false;
  constructor(
    private webFireService: WebsiteServiceService
  ) { }

  ngOnInit() {
    this.loadGenNotifications();
    this.loadExamNotifications();
    this.loadTTNotifications();
  }

  loadGenNotifications() {
    this.loadingGen = true;
    const List = this.webFireService.getNotifications('general', 4)
    List.snapshotChanges().subscribe(items => {
      this.generalNotiList = [];
      items.forEach(item => {
        this.generalNotiList.push(
          item.payload.toJSON()
        )
      });
      this.loadingGen = false;
      this.generalNotiList.sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime());
      this.generalNotiList = this.generalNotiList.slice(0, 4);
      console.log(this.generalNotiList);
    });
  }
  loadExamNotifications() {
    this.loadingExam = true;
    const List = this.webFireService.getNotifications('exam', 4)
    List.snapshotChanges().subscribe(items => {
      this.examNotiList = [];
      items.forEach(item => {
        this.examNotiList.push(
          item.payload.toJSON()
        )
      });
      this.loadingExam = false;
      this.examNotiList.sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime());
      this.examNotiList = this.examNotiList.slice(0, 4);
      console.log(this.examNotiList);
    });
  }
  loadTTNotifications() {
    this.loadingTT = true;
    const List = this.webFireService.getNotifications('timetable', 4)
    List.snapshotChanges().subscribe(items => {
      this.timetableNotiList = [];
      items.forEach(item => {
        this.timetableNotiList.push(
          item.payload.toJSON()
        )
      });
      this.loadingTT = false;
      this.timetableNotiList.sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime());
      this.timetableNotiList = this.timetableNotiList.slice(0, 4);
      console.log(this.timetableNotiList);
    });
  }

}
