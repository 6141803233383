import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WebsiteServiceService } from 'app/services/website-service.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-view-result-page',
  templateUrl: './view-result-page.component.html',
  styleUrls: ['./view-result-page.component.scss']
})
export class ViewResultPageComponent implements OnInit {
  searchType = "Name";
  // rollNoSelected = false;
  dynamicInput = "";

  selectedStudents = [];
  searchedStudents = [];
  currentResult = null;
  deletingNotificationId: any = null;
  loading = false;
  isEmptyList = false;
  result = null
  constructor(
    private route: ActivatedRoute,
    private webFireService: WebsiteServiceService,
    private toastr: ToastrService
  ) {
  }
  ngOnInit() {

    this.route.paramMap.subscribe(params => {
      // this.loadResults(params.get('id'));
      this.loadSingleResult(params.get('id'));

      window.scrollTo(0, 0);
    });
  }
  loadSingleResult(id) {
    this.loading = true;
    this.webFireService.getResultData(id).then(snapshot => {
      if (snapshot.val()) {

        this.selectedStudents = [];
        this.result = snapshot.val();
        let resultData = snapshot.val()['students'];
        this.selectedStudents = Object.keys(resultData).map(key => {
          return resultData[key];
        })
        // this.selectedStudents = snapshot.val()['students']
        this.selectedStudents.sort((a, b) => a.roll_no - b.roll_no);
      }
      console.log(this.selectedStudents);

      this.loading = false;
    });
  }

  loadResults(id) {
    this.loading = true;
    const List = this.webFireService.getResultStudentList(id);
    List.snapshotChanges().subscribe(items => {
      this.selectedStudents = [];
      items.forEach(item => {
        const jsonItem = item.payload.toJSON();
        this.selectedStudents.push(jsonItem);
      });
      this.selectedStudents.sort((a, b) => a.roll_no - b.roll_no);
      console.log(this.selectedStudents);
      this.loading = false;
    });
  }

  searchByDynamicInput() {
    if (!this.dynamicInput) {
      this.toastr.clear()
      this.toastr.show("Enter " + this.searchType.replace('_', " "))
      return;
    }
    switch (this.searchType) {
      case "Name":
        console.log(this.dynamicInput);

        if (this.dynamicInput.length < 3) {
          this.toastr.clear()
          this.toastr.show("Enter minumum 3 characters")
          return;
        }
        this.searchedStudents = this.selectedStudents.filter(item => item.student_name.toLowerCase().includes(this.dynamicInput.toLowerCase()))
        break;
      case "Roll_No":
        console.log(this.dynamicInput);
        this.searchedStudents = this.selectedStudents.filter(item => item.roll_no == this.dynamicInput)
        break;
    }

    console.log(this.searchedStudents);

    this.isEmptyList = this.searchedStudents.length == 0 ? true : false;

  }
  handleChangeEvent(btn) {
    switch (btn) {
      case "Name":
        this.dynamicInput = "";
        break;
      case "Roll_No":
        this.dynamicInput = "";
        break;
    }
  }
}
