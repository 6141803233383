import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';

import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AngularFireDatabase } from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {

  constructor(
    public afAuth: AngularFireAuth,
    private toastr: ToastrService,
    private router: Router,
    private fireDataBase: AngularFireDatabase,
  ) {

  }

  loginWithEmailPassword(email, pass) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, pass).then((value) => {
      console.log(value);
    });
  }


  getUserProfile(id) {
    return this.fireDataBase.database.ref('users/' + id).once('value', function (snapshot) {
    }, function (error) {
      console.log(error);
    });
  }
  signout() {
    this.afAuth.auth.signOut();
    console.log('signed-out');
    this.router.navigate(['/admin-login']);
  }
  showSuccess() {
    this.toastr.success('Hello world!', 'Toastr fun!');
  }
}
