import { Component, OnInit } from '@angular/core';
import { WebsiteServiceService } from 'app/services/website-service.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-web-footer',
  templateUrl: './web-footer.component.html',
  styleUrls: ['./web-footer.component.scss']
})
export class WebFooterComponent implements OnInit {
  studentFeedBack = {
    name: '',
    email: '',
    phone: '',
    message: ''
  }
  disableForm = false;
  constructor(
    private webFirebase: WebsiteServiceService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
  }
  addStudentFeedBackToFirebase() {
    if (this.studentFeedBack.name.trim() === '') {
      this.toastr.warning('Enter name')
      return
    }
    if (this.studentFeedBack.email.trim() === '') {
      this.toastr.warning('Enter email')
      return
    }
    if (this.studentFeedBack.phone.trim() === '') {
      this.toastr.warning('Enter Phone')
      return
    }
    if (this.studentFeedBack.message.trim() === '') {
      this.toastr.warning('Enter Message')
      return
    }
    this.disableForm = true;
    this.webFirebase.addStudentFeedBack(this.studentFeedBack).then(data => {
      this.disableForm = false;
      this.studentFeedBack = {
        name: '',
        email: '',
        phone: '',
        message: ''
      }
      this.toastr.success('Message sent.')
    }).catch(err => {
      console.log(err);
      this.disableForm = false;
    })
  }
}
