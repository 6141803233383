// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

var firebaseConfigStage= {
  apiKey: "AIzaSyDG3lnAz7YWSfeAHaHxX2Z1LbKAddqdC9M",
  authDomain: "ignite-classes-2023.firebaseapp.com",
  databaseURL: "https://ignite-classes-2023-default-rtdb.firebaseio.com",
  projectId: "ignite-classes-2023",
  storageBucket: "ignite-classes-2023.appspot.com",
  messagingSenderId: "896610481242",
  appId: "1:896610481242:web:c7f9e3f811852515299463",
  measurementId: "G-YQ2L2ZN2JR"
};
var firebaseConfigProduction= {
  apiKey: "AIzaSyDG3lnAz7YWSfeAHaHxX2Z1LbKAddqdC9M",
  authDomain: "ignite-classes-2023.firebaseapp.com",
  databaseURL: "https://ignite-classes-2023-default-rtdb.firebaseio.com",
  projectId: "ignite-classes-2023",
  storageBucket: "ignite-classes-2023.appspot.com",
  messagingSenderId: "896610481242",
  appId: "1:896610481242:web:c7f9e3f811852515299463",
  measurementId: "G-YQ2L2ZN2JR"
}

export const environment = {
  production: false,
  firebaseConfig: firebaseConfigProduction
};
