import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent implements OnInit {
  showScrollButton = false;
  constructor() {

  }
  ngOnInit() {
    window.addEventListener('scroll', this.scrollEvent, true);
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scrollEvent, true);
  }

  scrollEvent = (event: any): void => {
    // const n = event.srcElement.scrollingElement.scrollTop;
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      this.showScrollButton = true;
    } else {
      this.showScrollButton = false;
    }
  }
  moveToTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
}
