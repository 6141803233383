import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireDatabaseModule, AngularFireList } from '@angular/fire/database';
import * as firebase from 'firebase';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class StudentFirebaseService {


  constructor(
    private fireDataBase: AngularFireDatabase,
    private spinner: NgxSpinnerService
  ) {

  }



  addStudentToFirebase(student) {
    this.spinner.show()
    if (!student) {
      return;
    }
    student.time = firebase.database.ServerValue.TIMESTAMP;
    const ref = this.fireDataBase.list('students').push(student);
    student.id = ref.key;
    return this.fireDataBase.list('students/').update(ref.key, student).then(data => {
      this.spinner.hide();
    })
  }
  getStudentList() {
    return this.fireDataBase.list('students');
  }
  getTestRegList() {
    return this.fireDataBase.list('testSeriesReg');
  }
  getStudentById(id) {
    return this.fireDataBase.database.ref('students/' + id).once('value', function (snapshot) {

    }, function (error) {
      console.log(error);
    });
  }
  updateStudentReg(student) {
    if (!student) {
      return null;
    }
    return this.fireDataBase.list('testSeriesReg/').update(student.id, student);
  }
}
