import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-web-admin-login',
  templateUrl: './web-admin-login.component.html',
  styleUrls: ['./web-admin-login.component.scss']
})
export class WebAdminLoginComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
