import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { NgxSpinnerService } from 'ngx-spinner';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class WebsiteServiceService {

  constructor(
    private fireDataBase: AngularFireDatabase,
    private spinner: NgxSpinnerService
  ) { }

  addNotificationToFirebase(notification) {
    this.spinner.show()
    if (!notification) {
      return;
    }
    notification.time = firebase.database.ServerValue.TIMESTAMP;
    const ref = this.fireDataBase.list('notifications').push(notification);
    notification.id = ref.key;
    return this.fireDataBase.list('notifications/').update(ref.key, notification).then(data => {
      this.spinner.hide();
    });
  }
  getNotifications(type, limit) {
    return this.fireDataBase.list('notifications', ref =>
      ref.orderByChild('type').equalTo(type)
    );
  }

  getNotificationData(id) {
    return this.fireDataBase.database.ref('notifications/' + id).once('value', function (snapshot) {

    }, function (error) {
      console.log(error);
    });
  }
  getAllNotificationsonType(type) {
    return this.fireDataBase.list('notifications', ref => ref.orderByChild('type').equalTo(type));
  }
  getAllNotifications() {
    return this.fireDataBase.list('notifications');
  }
  getAllResults() {
    return this.fireDataBase.list('results');
  }

  getResultStudentList(id) {
    return this.fireDataBase.list('results/' + id + "/students");
  }

  getResultData(id) {
    return this.fireDataBase.database.ref('results/' + id).once('value', function (snapshot) {

    }, function (error) {
      console.log(error);
    });
  }
  deleteNotification(id) {
    if (!id) {
      return;
    }
    return this.fireDataBase.list('notifications').remove(id);
  }
  addStudentFeedBack(feedBack) {
    if (!feedBack) {
      return;
    }
    feedBack.time = firebase.database.ServerValue.TIMESTAMP;
    const ref = this.fireDataBase.list('feedback').push(feedBack);
    feedBack.id = ref.key;
    return this.fireDataBase.list('feedback/').update(ref.key, feedBack)
  }
  addWebsiteFeedBack(feedBack) {
    if (!feedBack) {
      return;
    }
    feedBack.time = firebase.database.ServerValue.TIMESTAMP;
    const ref = this.fireDataBase.list('webFeedback').push(feedBack);
    feedBack.id = ref.key;
    return this.fireDataBase.list('webFeedback/').update(ref.key, feedBack)
  }
  addRegistration(feedBack) {
    if (!feedBack) {
      return;
    }
    feedBack.time = firebase.database.ServerValue.TIMESTAMP;
    const ref = this.fireDataBase.list('testSeriesReg').push(feedBack);
    feedBack.id = ref.key;
    return this.fireDataBase.list('testSeriesReg/').update(ref.key, feedBack)
  }
}
// testSeriesReg