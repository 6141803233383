import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements OnInit {
  videoList = [
    {
      title: 'IGNITE COACHING ACADEMY CHANDILORA',
      url: 'https://www.youtube.com/embed/K57H8BogLR0'
    }
  ]
  constructor(
    private domSanitizer: DomSanitizer
  ) {

  }
  getSanitizedUrl(url) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
  ngOnInit() {
  }

}
