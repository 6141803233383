import { Component, OnInit } from '@angular/core';
// import { ICarouselConfig, AnimationConfig } from 'angular4-carousel';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
