import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faculty',
  templateUrl: './faculty.component.html',
  styleUrls: ['./faculty.component.scss']
})
export class FacultyComponent implements OnInit {
  facultyList = [
    {
      imageUrl: '../../../assets/img/faculty-cards/f1.png'
    },
    {
      imageUrl: '../../../assets/img/faculty-cards/f2.png'
    },
    {
      imageUrl: '../../../assets/img/faculty-cards/f3.png'
    },
    {
      imageUrl: '../../../assets/img/faculty-cards/f4.png'
    },
    {
      imageUrl: '../../../assets/img/faculty-cards/f5.png'
    },
    {
      imageUrl: '../../../assets/img/faculty-cards/f6.png'
    },
    {
      imageUrl: '../../../assets/img/faculty-cards/f7.png'
    },
    {
      imageUrl: '../../../assets/img/faculty-cards/f8.png'
    },
    {
      imageUrl: '../../../assets/img/faculty-cards/f9.png'
    }
  ]
  constructor() { }

  ngOnInit() {
  }

}
