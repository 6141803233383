import { Component, OnInit } from '@angular/core';
import { WebsiteServiceService } from 'app/services/website-service.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-test-series-reg',
  templateUrl: './test-series-reg.component.html',
  styleUrls: ['./test-series-reg.component.scss']
})
export class TestSeriesRegComponent implements OnInit {
  feedBack = {
    name: '',
    email: '',
    phone: '',
    // message: ''
    isHopeStudent: false
  }
  disableForm = false;
  submitted = false;
  constructor(
    private webFirebase: WebsiteServiceService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
  }

  addFeedBackToFirebase() {
    if (this.feedBack.name.trim() === '') {
      this.toastr.warning('Enter name')
      return
    }
    if (this.feedBack.email.trim() === '') {
      this.toastr.warning('Enter email')
      return
    }
    if (this.feedBack.phone.trim() === '') {
      this.toastr.warning('Enter Phone')
      return
    }
    // if (this.feedBack.message.trim() === '') {
    //   this.toastr.warning('Enter Message')
    //   return
    // }
    this.disableForm = true;
    this.webFirebase.addRegistration(this.feedBack).then(data => {
      this.submitted = true;
      this.disableForm = false;
      this.feedBack = {
        name: '',
        email: '',
        phone: '',
        // message: ''
        isHopeStudent: false
      }
      this.toastr.success('Please visit institute to pay the registration fee.', 'Registration Successful.')
    }).catch(err => {
      console.log(err);
      this.disableForm = false;
      this.submitted = false;
    })
  }
}
