import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-web-navbar',
  templateUrl: './web-navbar.component.html',
  styleUrls: ['./web-navbar.component.scss']
})
export class WebNavbarComponent implements OnInit {
  menuOpen = false;
  constructor() { }

  ngOnInit() {
  }
  scrollToBottom() {
    window.scrollTo(0, document.body.scrollHeight);
  }
  openMenu() {
    this.menuOpen = !this.menuOpen;
  }
}
