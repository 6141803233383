import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss']
})
export class ImageSliderComponent implements OnInit {
  slideList = [
    {
      title: '',
      subTitle: '',
      imageUrl: '../../../assets/img/slider-pics/0.jpeg'
    },
    {
      title: '',
      subTitle: '',
      imageUrl: '../../../assets/img/slider-pics/1.jpeg'
    },
    {
      title: '',
      subTitle: '',
      imageUrl: '../../../assets/img/slider-pics/2.jpeg'
    },
    {
      title: '',
      subTitle: '',
      imageUrl: '../../../assets/img/slider-pics/3.jpeg'
    },
    {
      title: '',
      subTitle: '',
      imageUrl: '../../../assets/img/slider-pics/4.jpeg'
    },
    {
      title: '',
      subTitle: '',
      imageUrl: '../../../assets/img/slider-pics/5.jpeg'
    },
    {
      title: '',
      subTitle: '',
      imageUrl: '../../../assets/img/slider-pics/6.jpeg'
    },
    {
      title: '',
      subTitle: '',
      imageUrl: '../../../assets/img/slider-pics/7.jpeg'
    },
    {
      title: '',
      subTitle: '',
      imageUrl: '../../../assets/img/slider-pics/8.jpeg'
    },
    {
      title: '',
      subTitle: '',
      imageUrl: '../../../assets/img/slider-pics/9.jpeg'
    },
  ]
  constructor() { }

  ngOnInit() {
  }

}
