import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {
  fileReaded
  constructor() { }

  ngOnInit() {
  }

  convertFile(csv: any) {
    this.fileReaded = csv.target.files[0];

    const reader: FileReader = new FileReader();
    reader.readAsText(this.fileReaded);

    reader.onload = (e) => {
      // tslint:disable-next-line:no-shadowed-variable
      const csv: any = reader.result;
      const allTextLines = csv.split(/\r|\n|\r/);
      const headers = allTextLines[0].split(',');
      const lines = [];

      for (let i = 0; i < allTextLines.length; i++) {
        // split content based on comma
        const data = allTextLines[i].split(',');
        if (data.length === headers.length) {
          const tarr = [];
          for (let j = 0; j < headers.length; j++) {
            tarr.push(data[j]);
          }

          // log each row to see output
          console.log(tarr);
          lines.push(tarr);
        }
      }
      // all rows in the csv file
      console.log('>>>>>>>>>>>>>>>>>', lines);
    }
  }
}
