import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-page',
  templateUrl: './no-page.component.html',
  styleUrls: ['./no-page.component.scss']
})
export class NoPageComponent implements OnInit {
  url = '';
  constructor() { }

  ngOnInit() {
    console.log(window.location.href);
    this.url = window.location.href;
  }
  contactDeveloper() {
    window.location.href = 'https://reyaz.co.in/contact'
  }
}
