import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-testmonials',
  templateUrl: './testmonials.component.html',
  styleUrls: ['./testmonials.component.scss']
})
export class TestmonialsComponent implements OnInit {
  testList = [
    {
      id: '1',
      name: 'HUMAIRA HILAL',
      // tslint:disable-next-line:max-line-length
      message: 'I am happy to be a product of this esteemed institution.  IGNITE CLASSES CHANDILORA, not only provides a platform to enrich academically but also emphasises on the all-around development of the student. It provided a lot of opportunities for students to showcase their talents. These activities create a very pleasant and enriching atmosphere for students as well as teachers as we enjoy it a lot.',
      desig: 'CLASS 12TH',
      imageUrl: '',
    },
    {
      id: '2',
      name: 'Tabid Reyaz',
      message: 'In my experience all the teachers are very supportive and friendly and the placement process has been very smooth throughout. I would always be very grateful for the lifelong connections I made.',
      desig: '',
      imageUrl: '',
    },
    {
      id: '2',
      name: 'Danish FAYAZ ',
      message: 'My experience at IGNITE CLASSES CHANDILORA is great and memorable. The mentors at IGNITE CLASSES  helped to enhance my academic and interpersonal skills. I am thankful for providing a platform to enhance my knowledge and an opportunity. In the last I am saying that The World is here at IGNITE CLASSES.',
      desig: 'CLASS 11TH',
      imageUrl: '',
    },
    {
      id: '2',
      name: 'NASIR AHMAD',
      message: 'It was my immense luck and fortune to be the part of IGNITE CLASSES CHANDILORA.where I can grow. The entire faculty and department leaves no stone unturned to shape one\'s future. My 2 years at IGNITE have been a wonderful experience of learning with prolific exposure to outside. Huge respect, love and devotion for entire faculty members and department. It\'s their efforts that make me to count myself into better STUDENTS.',
      desig: 'Class 12th',
      imageUrl: '',
    },
  ]
  constructor() { }

  ngOnInit() {
  }

}
