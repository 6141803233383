import { Component, OnInit } from '@angular/core';
import { AuthServiceService } from 'app/services/auth-service.service';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    // { path: '/admin/dashboard', title: 'Dashboard', icon: 'dashboard', class: '' },
    { path: '/admin/add-student', title: 'Add Student', icon: 'person_add', class: '' },
    { path: '/admin/list-students', title: 'Student List', icon: 'people', class: '' },
    { path: '/admin/reg-list', title: 'Test Series Reg', icon: 'people', class: '' },
    // { path: '/admin/view-student', title: 'View Student', icon: 'person', class: '' },
    // { path: '/admin/user-profile', title: 'User Profile', icon: 'person', class: '' },
    // { path: '/admin/table-list', title: 'Table List', icon: 'content_paste', class: '' },
    // { path: '/admin/typography', title: 'Typography', icon: 'library_books', class: '' },
    // { path: '/admin/icons', title: 'Icons', icon: 'bubble_chart', class: '' },
    // { path: '/admin/maps', title: 'Maps', icon: 'location_on', class: '' },
    // { path: '/admin/notifications', title: 'Notifications', icon: 'notifications', class: '' },
    // { path: '/admin/upgrade', title: 'Upgrade to PRO',  icon:'unarchive', class: 'active-pro' },
];

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
    menuItems: any[];

    constructor(
        public authService: AuthServiceService,
    ) { }

    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
    }
    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };

    signOut() {
        this.authService.signout()
    }
}
