import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WebsiteServiceService } from 'app/services/website-service.service';

@Component({
  selector: 'app-view-single-notification',
  templateUrl: './view-single-notification.component.html',
  styleUrls: ['./view-single-notification.component.scss']
})
export class ViewSingleNotificationComponent implements OnInit {
  currentNotification = {
    id: '',
    title: '',
    desc: '',
    time: '',
    fileUrl: ''
  }
  loading = false;
  constructor(
    private route: ActivatedRoute,
    private webFireService: WebsiteServiceService
  ) { }

  ngOnInit() {

    this.route.paramMap.subscribe(params => {
      this.loadNotification(params.get('id'));

      // window.scrollTo(0, 0);
    });

  }
  loadNotification(id) {
    this.loading = true;
    this.webFireService.getNotificationData(id).then(snapshot => {
      if (snapshot.val()) {
        this.currentNotification = snapshot.val();
        console.log(this.currentNotification);

      }
      this.loading = false;
    });
  }
}
