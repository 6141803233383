import { Component, OnInit } from '@angular/core';
import { WebsiteServiceService } from 'app/services/website-service.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss']
})
export class FeedbackFormComponent implements OnInit {
  feedBack = {
    name: '',
    email: '',
    phone: '',
    message: ''
  }
  disableForm = false;
  constructor(
    private webFirebase: WebsiteServiceService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
  }

  addFeedBackToFirebase() {
    if (this.feedBack.name.trim() === '') {
      this.toastr.warning('Enter name')
      return
    }
    if (this.feedBack.email.trim() === '') {
      this.toastr.warning('Enter email')
      return
    }
    if (this.feedBack.phone.trim() === '') {
      this.toastr.warning('Enter Phone')
      return
    }
    if (this.feedBack.message.trim() === '') {
      this.toastr.warning('Enter Message')
      return
    }
    this.disableForm = true;
    this.webFirebase.addWebsiteFeedBack(this.feedBack).then(data => {
      this.disableForm = false;
      this.feedBack = {
        name: '',
        email: '',
        phone: '',
        message: ''
      }
      this.toastr.success('Thanks for your time.', 'Feedback submitted.')
    }).catch(err => {
      console.log(err);
      this.disableForm = false;
    })
  }
}
