import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-upload-json',
  templateUrl: './upload-json.component.html',
  styleUrls: ['./upload-json.component.scss']
})
export class UploadJsonComponent implements OnInit {
  students = []
  rawJsonArray = '';
  jsonToUpload = '';
  studentsJsonObject = {}
  constructor() { }

  ngOnInit() {
  }
  generateStudents() {
    this.students = [];




    const data = eval('(' + this.rawJsonArray + ')');
    // console.log(data);
    data.forEach(student => {
      // if (student.batch === 'PCM') {
      //   student['course'] = 'rcpcm'
      // } else if (student.batch === 'PCB') {
      //   student['course'] = 'f2pcb'
      // } else if (student.batch === 'PCMB') {
      //   student['course'] = 'f2pcmb'
      // }
      student['course'] = 'rce'
      // this.students.push(student);
      student['id'] = this.makeid();
      this.studentsJsonObject[student['id']] = student;
    });
    console.log(this.studentsJsonObject);
    this.rawJsonArray = JSON.stringify(this.studentsJsonObject);
  }
  makeid() {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 20; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
}
