import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './hope/home/home.component';
import { NoPageComponent } from './no-page/no-page.component';
import { DirDeskComponent } from './hope/dir-desk/dir-desk.component';
import { CourseComponent } from './hope/course/course.component';
import { VideosComponent } from './hope/videos/videos.component';
import { FacultyComponent } from './hope/faculty/faculty.component';
import { RankersStarsComponent } from './hope/rankers-stars/rankers-stars.component';
import { MainPageComponent } from './hope/main-page/main-page.component';
import { AboutPageComponent } from './hope/about-page/about-page.component';
import { TimeTableComponent } from './hope/time-table/time-table.component';
import { WebAdminComponent } from './hope/web-admin/web-admin.component';
import { AddNotificationsComponent } from './hope/add-notifications/add-notifications.component';
import { ViewNotificationsComponent } from './hope/view-notifications/view-notifications.component';
import { ViewSingleNotificationComponent } from './hope/view-single-notification/view-single-notification.component';
import { AllNotificationsComponent } from './hope/all-notifications/all-notifications.component';
import { TeacherComponent } from './hope/teacher/teacher.component';
import { FeedbackFormComponent } from './hope/feedback-form/feedback-form.component';
import { TestComponent } from './test/test.component';
import { MainAdminComponent } from './main-admin/main-admin.component';
import { UploadJsonComponent } from './upload-json/upload-json.component';
import { TestSeriesRegComponent } from './test-series-reg/test-series-reg.component';
import { StudentRegComponent } from './student-reg/student-reg.component';
import { HopeNotificationsComponent } from './hope/hope-notifications/hope-notifications.component';
import { ContactPageComponent } from './hope/contact-page/contact-page.component';
import { ResultPageComponent } from './hope/result-page/result-page.component';
import { ViewResultPageComponent } from './hope/view-result-page/view-result-page.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'web-admin',
    redirectTo: 'web-admin/add-notifications',
    pathMatch: 'full',
  },
  {
    path: 'web-admin',
    component: WebAdminComponent,
    children: [
      {
        path: 'add-notifications',
        component: AddNotificationsComponent
      },
      {
        path: 'view-notifications',
        component: ViewNotificationsComponent
      },
    ]
  },
  {
    path: '',
    component: MainPageComponent,
    children: [
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'directors-desk',
        component: DirDeskComponent
      },
      {
        path: 'courses',
        component: CourseComponent
      },
      {
        path: 'videos',
        component: VideosComponent
      },
      {
        path: 'faculty',
        component: FacultyComponent
      },
      {
        path: 'rankers-stars',
        component: RankersStarsComponent
      },
      {
        path: 'about',
        component: AboutPageComponent
      },
      {
        path: 'contact',
        component: ContactPageComponent
      },
      {
        path: 'results',
        component: ResultPageComponent
      },
      {
        path: 'results/:id',
        component: ViewResultPageComponent
      },
      {
        path: 'timetable',
        component: TimeTableComponent
      },
      {
        path: 'notification/:id',
        component: ViewSingleNotificationComponent
      },
      {
        path: 'notice-board',
        component: HopeNotificationsComponent
      },
      {
        path: 'desk/:name',
        component: TeacherComponent
      },
      {
        path: 'all-notification/:type',
        component: AllNotificationsComponent
      },
      {
        path: 'feedback',
        component: FeedbackFormComponent
      },
      {
        path: 'HC-RTS-2019',
        component: TestSeriesRegComponent
      }
    ]
  },
  {
    path: 'admin',
    redirectTo: 'admin/add-student',
    pathMatch: 'full',
  },
  {
    path: 'main-admin',
    redirectTo: 'main-admin/upload-json',
    pathMatch: 'full',
  },
  {
    path: 'main-admin',
    component: MainAdminComponent,
    children: [
      {
        path: 'upload-json',
        component: UploadJsonComponent
      }
    ]
  },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
      }
    ]
  },
  {
    path: 'admin-login',
    component: AdminLoginComponent
  },
  // { path: 'dashboard',      component: DashboardComponent },
  // { path: 'user-profile',   component: UserProfileComponent },
  // { path: 'table-list',     component: TableListComponent },
  // { path: 'typography',     component: TypographyComponent },
  // { path: 'icons',          component: IconsComponent },
  // { path: 'maps',           component: MapsComponent },
  // { path: 'notifications',  component: NotificationsComponent },
  // { path: 'upgrade',        component: UpgradeComponent },
  // { path: '',               redirectTo: 'dashboard', pathMatch: 'full' }

  {
    path: '**',
    component: NoPageComponent
  },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
