import { Component, OnInit } from '@angular/core';
import { WebsiteServiceService } from 'app/services/website-service.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-view-notifications',
  templateUrl: './view-notifications.component.html',
  styleUrls: ['./view-notifications.component.scss']
})
export class ViewNotificationsComponent implements OnInit {
  allNotificationList = [];
  deletingNotificationId: any = null;
  loading = false;
  constructor(
    private webFireService: WebsiteServiceService,
    private toastr: ToastrService
  ) {
    this.loadNotifications();
  }

  ngOnInit() {
  }

  loadNotifications() {
    this.loading = true;
    const List = this.webFireService.getAllNotifications();
    List.snapshotChanges().subscribe(items => {
      this.allNotificationList = [];
      items.forEach(item => {
        const jsonItem = item.payload.toJSON();
        this.allNotificationList.push(jsonItem);
      });
      this.allNotificationList.sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime());
      // console.log(this.allNotificationList);
      this.loading = false;
    });
  }
  deleteNotification(id) {
    this.deletingNotificationId = id
    this.webFireService.deleteNotification(id).then(data => {
      this.toastr.success('Notification Deleted');
      this.deletingNotificationId = null;
    })
  }
}
