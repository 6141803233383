import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';


import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { TableListComponent } from './table-list/table-list.component';
import { TypographyComponent } from './typography/typography.component';
import { IconsComponent } from './icons/icons.component';
import { MapsComponent } from './maps/maps.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { UpgradeComponent } from './upgrade/upgrade.component';
import {
  AgmCoreModule
} from '@agm/core';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AddStudentComponent } from './add-student/add-student.component';
import { ListStudentsComponent } from './list-students/list-students.component';
import { ViewStudentComponent } from './view-student/view-student.component';
import { LoginComponent } from './login/login.component';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { HomeComponent } from './hope/home/home.component';
import { NoPageComponent } from './no-page/no-page.component';

// FIREBASE STUFF
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireDatabase, AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AuthServiceService } from './services/auth-service.service';


import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';

import { StudentFirebaseService } from './services/student-firebase.service';
import { WebNavbarComponent } from './hope/web-navbar/web-navbar.component';
import { DirDeskComponent } from './hope/dir-desk/dir-desk.component';
import { CourseComponent } from './hope/course/course.component';
import { VideosComponent } from './hope/videos/videos.component';
import { FacultyComponent } from './hope/faculty/faculty.component';
import { RankersStarsComponent } from './hope/rankers-stars/rankers-stars.component';
import { WebFooterComponent } from './hope/web-footer/web-footer.component';
import { MainPageComponent } from './hope/main-page/main-page.component';
import { AboutPageComponent } from './hope/about-page/about-page.component';
import { TimeTableComponent } from './hope/time-table/time-table.component';
import { ImageSliderComponent } from './hope/image-slider/image-slider.component';
import { HopeNotificationsComponent } from './hope/hope-notifications/hope-notifications.component';
import { TestmonialsComponent } from './hope/testmonials/testmonials.component';
import { WebAdminComponent } from './hope/web-admin/web-admin.component';
import { WebAdminLoginComponent } from './hope/web-admin-login/web-admin-login.component';
import { AddNotificationsComponent } from './hope/add-notifications/add-notifications.component';
import { ViewNotificationsComponent } from './hope/view-notifications/view-notifications.component';
import { ViewSingleNotificationComponent } from './hope/view-single-notification/view-single-notification.component';
import { WebsiteServiceService } from './services/website-service.service';
import { CssRippleComponent } from './css/css-ripple/css-ripple.component';
import { AllNotificationsComponent } from './hope/all-notifications/all-notifications.component';
import { TeacherComponent } from './hope/teacher/teacher.component';
import { FeedbackFormComponent } from './hope/feedback-form/feedback-form.component';
import { TestComponent } from './test/test.component';
import { MainAdminComponent } from './main-admin/main-admin.component';
import { UploadJsonComponent } from './upload-json/upload-json.component';
import { MatTabsModule } from '@angular/material';
import { TestSeriesRegComponent } from './test-series-reg/test-series-reg.component';
import { ContactPageComponent } from './hope/contact-page/contact-page.component';
import { ResultPageComponent } from './hope/result-page/result-page.component';
import { ViewResultPageComponent } from './hope/view-result-page/view-result-page.component';
import { DirectorsComponent } from './hope/directors/directors.component';

// import { CarouselModule } from 'angular4-carousel';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,

    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    MatTabsModule,

    // FIREBASE
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule,


    // CarouselModule,


    AgmCoreModule.forRoot({
      apiKey: ''
    }),
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
    AdminLoginComponent,
    HomeComponent,
    NoPageComponent,
    WebNavbarComponent,
    DirDeskComponent,
    CourseComponent,
    VideosComponent,
    FacultyComponent,
    DirectorsComponent,
    RankersStarsComponent,
    WebFooterComponent,
    MainPageComponent,
    AboutPageComponent,
    TimeTableComponent,
    ImageSliderComponent,
    HopeNotificationsComponent,
    TestmonialsComponent,
    WebAdminComponent,
    WebAdminLoginComponent,
    AddNotificationsComponent,
    ViewNotificationsComponent,
    ViewSingleNotificationComponent,
    CssRippleComponent,
    AllNotificationsComponent,
    TeacherComponent,
    FeedbackFormComponent,
    TestComponent,
    MainAdminComponent,
    UploadJsonComponent,
    TestSeriesRegComponent,
    ContactPageComponent,
    ResultPageComponent,
    ViewResultPageComponent
  ],
  providers: [
    AuthServiceService,
    StudentFirebaseService,
    WebsiteServiceService,
    AngularFireDatabase
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
