import { Component, OnInit } from '@angular/core';
import { AuthServiceService } from 'app/services/auth-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-web-admin',
  templateUrl: './web-admin.component.html',
  styleUrls: ['./web-admin.component.scss']
})
export class WebAdminComponent implements OnInit {

  userProfile = null;
  authSubscriber;
  constructor(
    private authService: AuthServiceService,
    private spinner: NgxSpinnerService,
    private router: Router,
    public afAuth: AngularFireAuth,
  ) { }

  ngOnInit() {

    this.spinner.show();
    this.authSubscriber = this.afAuth.authState.subscribe((auth_) => {
      // console.log(auth);
      if (!auth_) {
        this.router.navigate(['/admin-login']);
      } else {
        this.loadProfile(auth_.uid).then(snapshot => {
          this.spinner.hide();
          if (snapshot.val()) {
            this.userProfile = snapshot.val();
            // console.log(this.userProfile);
            if (this.userProfile['userType'] !== 'notificationEditor' && this.userProfile['userType'] !== 'admin') {
              this.router.navigate(['/home']);
            }
          } else {
            console.log('problem in getting profile details');
          }
        }).catch(err => {
          console.log(err);
          this.spinner.hide();
        })
      }
    });
  }

  loadProfile(id) {
    return this.authService.getUserProfile(id)
  }

  logout() {
    this.authService.signout()
  }

  ngOnDestroy() {
    this.authSubscriber.unsubscribe();
    // console.log('web admin destroyed');

  }
}
