import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { AuthServiceService } from 'app/services/auth-service.service';

import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginComponent implements OnInit {
  user = {
    email: '',
    password: ''
  }
  userProfile = null;
  authSubscriber = null
  constructor(
    public afAuth: AngularFireAuth,
    public authService: AuthServiceService,
    private toastr: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {
    spinner.show();
    this.authSubscriber  = this.afAuth.authState.subscribe((auth_) => {
      // console.log(auth);
      if (auth_) {
        console.log('logged-in');
        this.loadProfile(auth_.uid).then(snapshot => {
          spinner.hide();
          if (snapshot.val()) {
            this.userProfile = snapshot.val();
            console.log(this.userProfile);
            if (this.userProfile['userType'] === 'notificationEditor') {
              this.router.navigate(['/web-admin']);
            } else if (this.userProfile['userType'] === 'admissionEditor') {
              this.router.navigate(['/admin']);
            } else if (this.userProfile['userType'] === 'admin') {
              this.router.navigate(['/main-admin']);
            }
          } else {
            console.log('problem in getting profile details');
          }
        }).catch(err => {
          console.log();
          spinner.hide();
        })
      } else {
        console.log('logged-out');
        spinner.hide();
      }
    });
  }
  loadProfile(id) {
    return this.authService.getUserProfile(id)
  }
  ngOnInit() {

  }

  login() {
    if (this.user.email.trim() === '') {
      this.toastr.warning('Enter email', 'You cannot leave email field empty');
      return
    }
    if (this.user.password.trim() === '') {
      this.toastr.warning('Enter password', 'You cannot leave password field empty');
      return
    }
    this.spinner.show();
    this.authService.loginWithEmailPassword(this.user.email, this.user.password).then(value => {
      // console.log(value);
      this.toastr.success('Logged In');
      this.spinner.hide();
    }).catch(err => {
      this.toastr.error(err.message);
      this.spinner.hide();
    });

  }

  ngOnDestroy() {
    this.authSubscriber.unsubscribe();
    console.log('web admin destroyed');
  }
}
